import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { IGrant } from '../../types/Grants/Grant'
import { IPagingResponse } from '../../types/Requests'
import {
  createGrantShareableUrlAsync,
  createGrantShareableUrlBuilder,
  fetchQuickSearchGrantsAsync,
  fetchQuickSearchGrantsAsyncBuilder,
} from './asyncRequests'

interface GrantsFilters {
  agencies: string[]
  phases: string[]
  statuses: string[]
  dates: {
    startDate: moment.Moment | null
    endDate: moment.Moment | null
  }
}

export interface GrantsInitialState {
  data: IGrant[]
  paging: IPagingResponse
  submitting: boolean
  filters: GrantsFilters
  error: string
}

const initialState: GrantsInitialState = {
  submitting: false,
  paging: {
    totalRows: 0,
    limit: 25,
    skip: 0,
    rows: 0,
    paging: true,
  },
  data: [],
  filters: {
    agencies: [],
    phases: [],
    statuses: ['open'],
    dates: {
      startDate: null,
      endDate: null,
    },
  },
  error: '',
}

const GrantsSlice = createSlice({
  name: 'grants',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      }
    },
    setPaging: (state, action) => {
      const {
        limit = state.paging.limit,
        skip = state.paging.skip,
        paging = state.paging.paging,
      } = action.payload
      state.paging = { ...state.paging, skip, limit, paging }
    },

    resetPaging: (state) => {
      state.paging = initialState.paging
    },

    resetGrants: (state) => {
      state.data = []
    },
  },
  extraReducers: (builder) => {
    fetchQuickSearchGrantsAsyncBuilder(builder)
    createGrantShareableUrlBuilder(builder)
  },
})

export const GrantsActions = {
  ...GrantsSlice.actions,
  fetchQuickSearchGrantsAsync,
  createGrantShareableUrlAsync,
}

export const GrantsReducer = GrantsSlice.reducer
