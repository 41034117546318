import { Box, Paper, Typography, useTheme } from '@mui/material'
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { useSelector } from 'react-redux'
import { MatricesSelector } from '../../../../store/TechnologyMatrices'

ChartJS.register(Title, Tooltip, Legend, CategoryScale, LinearScale, BarElement, BarController)

export const BarChart = () => {
  const theme = useTheme()
  const agencies = useSelector(MatricesSelector.selectAgencies)
  const agencyNames = Object.keys(agencies)
  const agencyCounts = Object.values(agencies)

  const data = {
    labels: agencyNames,
    datasets: [
      {
        label: 'Number of Grants',
        backgroundColor: '#00A94F',
        data: agencyCounts,
        barThickness: 80,
      },
    ],
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        labels: {
          usePointStyle: true,
          padding: 20,
          generateLabels: (chart: any) => {
            const data = chart.data
            if (data.labels.length && data.datasets.length) {
              return data.labels.map((label: any, index: any) => {
                const dataset = data.datasets[0]
                const backgroundColor = dataset.backgroundColor
                return {
                  text: `${label} - ${dataset.data[index]}`,
                  fillStyle: backgroundColor,
                  strokeStyle: backgroundColor,
                  lineWidth: 1,
                  hidden: isNaN(dataset.data[index]) || dataset.data[index] === 0,
                  index: index,
                }
              })
            }
            return []
          },
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          padding: 0,
        },
      },
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          padding: 7,
        },
      },
    },
  }

  return (
    <Paper
      elevation={0}
      style={{ padding: '20px', width: '100%', border: `1px solid ${theme.palette.grey[200]}` }}
    >
      <Box
        display="flex"
        justifyContent="flex-start"
        flexDirection="column"
        alignItems="flex-start"
        height="100%"
        p={4}
      >
        <Box width="100%">
          <Typography variant="h5" fontWeight={theme.typography.fontWeightBold}>
            Total grants
          </Typography>
        </Box>
        <Box width="100%">
          <Bar data={data} options={options} />
        </Box>
      </Box>
    </Paper>
  )
}
