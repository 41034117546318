export const ApiHttpPaths = {
  GET: {
    grants: {
      grant: (id: string) => ({
        protected: true,
        url: `/v1/grants/${id}`,
      }),
      grants: () => ({
        protected: true,
        url: '/v1/grants',
      }),
    },
    users: {
      users: () => ({
        protected: true,
        url: '/v1/users',
      }),
      user: (id: string) => ({
        protected: true,
        url: `/v1/users/${id}`,
      }),
      technologies: (userId: string) => ({
        protected: true,
        url: `/v1/users/${userId}/technologies`,
      }),

      currentUser: () => ({
        protected: true,
        url: '/v1/users/current',
      }),
      currentUserAvatar: () => ({
        protected: true,
        url: '/v1/users/current/avatar',
      }),

      registrationProgress: () => ({
        protected: true,
        url: '/v1/users/current/registrationProgress',
      }),

      company: () => ({
        protected: true,
        url: '/v1/users/current/company',
      }),
      companyMembers: () => ({
        protected: true,
        url: '/v1/users/current/company/members',
      }),
      companyPreferences: () => ({
        protected: true,
        url: '/v1/users/current/company/preferences',
      }),
    },
    technologies: {
      grants: (technologyId: string) => ({
        protected: true,
        url: `/v1/technologies/${technologyId}/matchedGrants`,
      }),
      grant: (technologyId: string, refId: string) => ({
        protected: true,
        url: `/v1/technologies/${technologyId}/matchedGrants/${encodeURIComponent(
          encodeURIComponent(refId),
        )}`,
      }),
      all: () => ({
        protected: true,
        url: `/v1/technologies`,
      }),
      technology: (techId: string) => ({
        protected: true,
        url: `/v1/technologies/${techId}`,
      }),
      technologyMatrices: (technologyId: string) => ({
        protected: true,
        url: `/v1/technologies/${technologyId}/matchedGrants/metrices`,
      }),
    },
    agencies: {
      agencies: () => ({
        protected: true,
        url: '/v1/agencies',
      }),
    },
    topics: {
      topics: () => ({
        protected: true,
        url: '/v1/topics',
      }),
    },
    subscription: {
      checkoutSession: () => ({
        protected: true,
        url: '/v1/subscription/stripe/session',
      }),
      stripePortalSession: () => ({
        protected: true,
        url: '/v1/subscription/stripe/portal/session',
      }),
      plans: () => ({
        protected: true,
        url: '/v1/products',
      }),
    },
    auth: {
      refreshJwt: () => ({
        protected: true,
        url: '/v1/auth/refreshjwt',
      }),
    },
    company: {
      companyMembers: (id: string) => ({
        protected: true,
        url: `/v1/companies/${id}/members`,
      }),
    },
    public: {
      grant: (grantId: string, accessKey: string) => ({
        protected: false,
        url: `/v1/public/shared/grants/${grantId}?key=${accessKey}`,
      }),
    },
    solicitations: {
      solicitation: (id: string) => ({
        protected: true,
        url: `/v1/solicitations/${id}`,
      }),
      solicitations: () => ({
        protected: true,
        url: '/v1/solicitations',
      }),
    },
  },
  POST: {
    grantMatch: {
      keywords: () => ({ protected: true, url: '/v1/grantMatch/openai/keywords' }),
      questions: () => ({ protected: true, url: '/v1/grantMatch/openai/questions' }),
      grants: () => ({ protected: true, url: '/v1/grantMatch/pinecone/grants' }),
      recommendations: () => ({ protected: true, url: '/v1/grantMatch/pinecone/recommendations' }),
    },
    subscription: {
      checkoutSession: () => ({
        protected: true,
        url: '/v1/subscription/stripe/session',
      }),
    },
    technology: {
      addtechnology: () => ({
        protected: true,
        url: '/v1/technologies',
      }),
      addGrant: (techId: string) => ({
        protected: true,
        url: `/v1/technologies/${techId}/matchedGrants`,
      }),
      addMember: (techId: string) => ({
        protected: true,
        url: `/v1/technologies/${techId}/members`,
      }),
    },
    company: {
      addCompanyMember: (companyId: string) => ({
        protected: true,
        url: `/v1/companies/${companyId}/members`,
      }),
    },
    auth: {
      login: () => ({
        protected: false,
        url: '/v1/auth/login',
      }),
      requestResetPassword: () => ({
        protected: true,
        url: '/v1/auth/resetPassword',
      }),
      requestResendEmail: () => ({
        protected: false,
        url: '/v1/auth/verifyEmail',
      }),
      googleSignup: () => ({
        protected: false,
        url: '/v1/auth/google/signup',
      }),
      linkedinSignup: () => ({
        protected: false,
        url: '/v1/auth/linkedin/signup',
      }),
      microsoftSignup: () => ({
        protected: false,
        url: '/v1/auth/microsoft/signup',
      }),
      googleSignupToken: () => ({
        protected: false,
        url: '/v1/auth/google/signup/token',
      }),
      linkedinSignupToken: () => ({
        protected: false,
        url: '/v1/auth/linkedin/signup/token',
      }),
      microsoftSignupToken: () => ({
        protected: false,
        url: '/v1/auth/microsoft/signup/token',
      }),
      googleAuthToken: () => ({
        protected: false,
        url: '/v1/auth/google/tokens',
      }),
      loginGoogle: () => ({
        protected: false,
        url: '/v1/auth/google/login',
      }),
      linkedinAuthToken: () => ({
        protected: false,
        url: '/v1/auth/linkedin/tokens',
      }),
      loginLinkedin: () => ({
        protected: false,
        url: '/v1/auth/linkedin/login',
      }),
      microsoftAuthToken: () => ({
        protected: false,
        url: '/v1/auth/microsoft/tokens',
      }),
      loginMicrosoft: () => ({
        protected: false,
        url: '/v1/auth/microsoft/login',
      }),
      registerUser: () => ({
        protected: false,
        url: '/v1/auth/register',
      }),
    },
    users: {
      currentUserAvatar: () => ({
        protected: true,
        url: '/v1/users/current/avatar',
      }),
      companyMembers: () => ({
        protected: true,
        url: '/v1/users/current/company/members',
      }),
    },
    grants: {
      search: () => ({
        protected: true,
        url: '/v1/grants/search',
      }),
      sharedGrants: (grantId: string) => ({
        protected: true,
        url: `/v1/grants/${grantId}/url`,
      }),
    },
  },
  PUT: {
    auth: {
      resetPassword: () => ({
        protected: true,
        url: '/v1/auth/resetPassword',
      }),
      verifyEmail: () => ({
        protected: false,
        url: '/v1/auth/verifyEmail',
      }),
      verifyCompanyInvitation: () => ({
        protected: false,
        url: '/v1/auth/verifyCompanyInvitation',
      }),
    },
    users: {
      currentUser: () => ({
        protected: true,
        url: '/v1/users/current',
      }),
      currentUserCompany: () => ({
        protected: true,
        url: '/v1/users/current/company',
      }),
      currentUserCompanyMember: (id: string) => ({
        protected: true,
        url: `/v1/users/current/company/members/${id}`,
      }),
      currentUserCompanyMemberRole: (id: string) => ({
        protected: true,
        url: `/v1/users/current/company/members/${id}/role`,
      }),
      registrationProgress: () => ({
        protected: true,
        url: '/v1/users/current/registrationProgress',
      }),
    },
    technology: {
      technologyGrants: (technologyId: string, refId: string) => ({
        protected: true,
        url: `/v1/technologies/${technologyId}/matchedGrants/${encodeURIComponent(
          encodeURIComponent(refId),
        )}`,
      }),
      updateTechnology: (technologyId: string) => ({
        protected: true,
        url: `/v1/technologies/${technologyId}`,
      }),
    },
  },
  DELETE: {
    technologies: {
      technology: (technologyId: string) => ({
        protected: true,
        url: `/v1/technologies/${technologyId}`,
      }),
    },
  },
}
