import { AutoAwesomeMosaic, Menu } from '@mui/icons-material'
import { Box, Button, Stack, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, GrantsSelector } from '../../../../store'
import { SolicitationsActions, SolicitationsSelector } from '../../../../store/solicitations'
import { SolicitationsGrid, SolicitationsTable } from './subComponents'
import { SolicitationPaging } from './subComponents/SolicitationPaging'

export const Solicitations = () => {
  const theme = useTheme()
  const dispatch = useDispatch<AppDispatch>()

  const [view, setView] = useState('list')

  const paging = useSelector(SolicitationsSelector.getPaging)

  const { limit, skip } = paging

  const filters = useSelector(GrantsSelector.makeSolicitationFilters)

  useEffect(() => {
    const options = {
      params: {
        $includes: 'grant,agency',
        $counts: 'grant',
        $limit: limit,
        $skip: skip,
        $filter: JSON.stringify(filters),
      },
    }

    dispatch(SolicitationsActions.fetchSolicitationsAsync({ options }))
  }, [limit, skip, filters])

  return (
    <Box mt={4}>
      <Stack direction="row" justifyContent="space-between">
        <Typography color="secondary" fontSize="1rem" fontWeight={theme.typography.fontWeightBold}>
          Solicitations
        </Typography>

        <Stack direction="row" gap={4}>
          <Button
            disableElevation
            variant={view === 'list' ? 'contained' : 'outlined'}
            onClick={() => setView('list')}
            startIcon={<Menu />}
            sx={{
              minWidth: 100,
              textTransform: 'capitalize',
            }}
          >
            List
          </Button>
          <Button
            disableElevation
            variant={view === 'grid' ? 'contained' : 'outlined'}
            onClick={() => setView('grid')}
            startIcon={<AutoAwesomeMosaic />}
            sx={{
              minWidth: 100,
              textTransform: 'capitalize',
            }}
          >
            Board
          </Button>
        </Stack>
      </Stack>

      <Box sx={{ my: 3, background: 'white', p: 3, borderRadius: '.5rem' }}>
        {view === 'list' && <SolicitationsTable />}
        {view === 'grid' && <SolicitationsGrid />}

        <SolicitationPaging />
      </Box>
    </Box>
  )
}
