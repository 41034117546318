import { Box, Typography, useTheme } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { SnackbarActions } from '../../../../store/snackbar'
import { MatricesActions, MatricesSelector } from '../../../../store/TechnologyMatrices'
import { BarChart } from './BarChart'
import { DoughnutChart } from './DoughnutChart'
import { GrantInfo } from './GrantInfo'

export const TechnologyMatrices: React.FC = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const params = useParams()
  const techId = params.id || ''
  const won = useSelector(MatricesSelector.selectWon)
  const loss = useSelector(MatricesSelector.selectLoss)
  const applied = useSelector(MatricesSelector.getAppliedGrants)

  useEffect(() => {
    const fetchMatrices = async () => {
      try {
        await dispatch(
          MatricesActions.fetchTechnologyMatricesAsync({
            techId: techId,
          }) as any,
        )
      } catch (error: any) {
        dispatch(SnackbarActions.showSnackbar({ message: error.message }))
      }
    }

    if (techId) {
      fetchMatrices()
    }
  }, [])

  return (
    <Box>
      <Typography variant="h6" fontWeight={theme.typography.fontWeightBold} gutterBottom px={6}>
        Matrices
      </Typography>
      <Box>
        <GrantInfo won={won} loss={loss} applied={applied} />
      </Box>
      <Box sx={{ p: 4 }}>
        <DoughnutChart />
      </Box>
      <Box sx={{ p: 4 }}>
        <BarChart />
      </Box>
    </Box>
  )
}
