import { Box, Divider, Typography } from '@mui/material'
import React from 'react'

interface GrantInfoProps {
  applied: number
  won: number
  loss: number
}

export const GrantInfo: React.FC<GrantInfoProps> = ({ applied, won, loss }) => {
  const data = [
    { text: 'Total applied grants', number: applied },
    { text: 'Total wins', number: won },
    { text: 'Total losses', number: loss },
  ]

  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: '100%' }}
    >
      {data.map((item, index) => (
        <React.Fragment key={index}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="subtitle1" align="center">
              {item.text}
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }} align="center">
              {item.number}
            </Typography>
          </Box>
          {index < data.length - 1 && (
            <Divider orientation="vertical" flexItem sx={{ mx: 2, height: 40 }} />
          )}
        </React.Fragment>
      ))}
    </Box>
  )
}
