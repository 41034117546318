import { Settings } from '@mui/icons-material'
import { Grid, IconButton, Stack, Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { TechnologySlideOutLayout } from '../../components/layouts'
import { AppDispatch } from '../../store'
import { AgenciesActions } from '../../store/agencies'
import { GrantMatchActions, GrantMatcherSelector } from '../../store/grantMatcher'
import { SnackbarActions } from '../../store/snackbar'
import { TechnologiesActions, TechnologiesSelectors } from '../../store/technologies'
import { TechnologyActions, TechnologySelectors } from '../../store/technology'
import { Members, TechnologyGrants } from './subComponents'
import { TechnologyDetail } from './subComponents/TechnologyDetail'
import { TechnologyMatrices } from './subComponents/TechnologyMatrices'

export const Technology = () => {
  const params = useParams()
  const dispatch = useDispatch<AppDispatch>()

  const [showGrantDetail, setShowGrantDetail] = useState<boolean>(false)

  const technologyId = params.id || ''

  const technologyError = useSelector(TechnologiesSelectors.error)
  const grantError = useSelector(GrantMatcherSelector.getGrantsError)
  const { title = '', description = '' } = useSelector(TechnologySelectors.getTechnology)

  useEffect(() => {
    if (technologyError || grantError) {
      const error = technologyError || grantError
      dispatch(SnackbarActions.showSnackbar({ message: error }))
    }

    return () => {
      dispatch(TechnologiesActions.resetTechnologyError())
      dispatch(GrantMatchActions.resetGrantsError())
    }
  }, [technologyError, grantError])

  const fetchTechnology = useCallback(async () => {
    if (technologyId) {
      await dispatch(
        TechnologyActions.fetchTechnologyAsync({
          id: technologyId,
          options: {
            params: {
              $includes: 'users',
            },
          },
        }),
      )
    }
  }, [technologyId, dispatch])

  useEffect(() => {
    dispatch(TechnologyActions.resetTechnologyData())
    dispatch(AgenciesActions.fetchAgenciesAsync())
    fetchTechnology()
  }, [fetchTechnology, dispatch])

  const handleDrawerClose = () => {
    setShowGrantDetail(false)
  }

  const handleDrawerOpen = () => {
    setShowGrantDetail(true)
  }

  return (
    <>
      <TechnologySlideOutLayout
        open={showGrantDetail}
        onClose={handleDrawerClose}
        children={<TechnologyDetail onSave={handleDrawerClose} />}
        children1={<TechnologyMatrices />}
      />

      <Grid container p={{ xs: 0, sm: 1 }} height="min-content">
        <Grid item container xs={12}>
          <Grid item xs={12} lg={8}>
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography
                variant="h5"
                sx={{ fontSize: '1.5rem', fontWeight: 500, color: 'secondary.main' }}
              >
                {title}
              </Typography>
              <IconButton size="small" onClick={handleDrawerOpen}>
                <Settings color="secondary" />
              </IconButton>
            </Stack>

            <Typography
              my="0.8rem"
              fontWeight={500}
              fontSize="0.875rem"
              color="secondary.light"
              whiteSpace="break-spaces"
            >
              {description}
            </Typography>
          </Grid>

          <Grid item xs={12} lg={4}>
            <Members />
          </Grid>
        </Grid>
        <Grid item xs={12} my="2rem">
          <TechnologyGrants technologyId={technologyId} />
        </Grid>
      </Grid>
    </>
  )
}
