import { createSelector } from '@reduxjs/toolkit'
import moment from 'moment'
import { IGrant } from '../../types/Grants'
import { convertPhase } from '../../utils'
import { RootState } from '../store'

const defaultAgency = {
  title: 'N/A',
  acronym: 'N/A',
  backgroundColor: '#e0e0e0',
  textColor: '#424242',
}

const getSolicitations = (state: RootState) => state.solicitations.solicitations.data || []
const getSolicitationsError = (state: RootState) => state.solicitations.solicitations.error
const fetchingSolicitations = (state: RootState) => state.solicitations.solicitations.fetching
const getPaging = (state: RootState) => state.solicitations.solicitations.paging

const getSolicitation = (state: RootState) => state.solicitations.solicitation.data
const getSolicitationError = (state: RootState) => state.solicitations.solicitation.error
const fetchingSolicitation = (state: RootState) => state.solicitations.solicitation.fetching

const makeSolicitations = createSelector(getSolicitations, (solicitations) => {
  return (
    solicitations &&
    solicitations.map((solicitation) => {
      const agencyDetail =
        solicitation.agency && typeof solicitation.agency !== 'string'
          ? solicitation.agency
          : defaultAgency

      const openDate = solicitation.open_date
        ? moment.utc(solicitation.open_date).format('MMMM D, YYYY')
        : ''
      const closeDate = solicitation.close_date
        ? moment.utc(solicitation.close_date).format('MMMM D, YYYY')
        : ''

      const phase = convertPhase(solicitation.phase)

      return {
        _id: solicitation._id,
        solicitationTitle: solicitation.solicitation_title || '',
        solicitationNumber: solicitation.solicitation_number || '',
        topicCount: solicitation?.grant_count || 0,
        program: solicitation.program || '',
        agency: agencyDetail,
        agency_abbr: solicitation.agency_abbr || '',
        branch: solicitation.branch || '',
        phase,
        openDate,
        closeDate,
      }
    })
  )
})

const makeSolicitation = createSelector(getSolicitation, (solicitation) => {
  if (!solicitation) return {}

  const agencyDetail =
    solicitation.agency && typeof solicitation.agency !== 'string'
      ? solicitation.agency
      : defaultAgency

  const openDate = solicitation.open_date
    ? moment.utc(solicitation.open_date).format('MMMM D, YYYY')
    : ''
  const closeDate = solicitation.close_date
    ? moment.utc(solicitation.close_date).format('MMMM D, YYYY')
    : ''

  const phase = convertPhase(solicitation.phase)

  return {
    _id: solicitation._id || '',
    solicitationTitle: solicitation.solicitation_title || '',
    solicitationNumber: solicitation.solicitation_number || '',
    solicitationYear: solicitation.solicitation_year,
    branch: solicitation.branch || '',
    program: solicitation.program || '',
    phase,
    openDate,
    closeDate,
    agency: agencyDetail,
  }
})

const makeGrants = createSelector(getSolicitation, (solicitation) => {
  if (!solicitation) return []

  const { grant = [] as IGrant[] } = solicitation

  const agencyDetail =
    solicitation.agency && typeof solicitation.agency !== 'string'
      ? solicitation.agency
      : defaultAgency

  const closeDate = solicitation.close_date
    ? moment.utc(solicitation.close_date).format('MMMM D, YYYY')
    : ''

  const phase = convertPhase(solicitation.phase)

  return grant.map((grantItem) => ({
    _id: grantItem._id || '',
    topicTitle: grantItem.topic_title || '',
    subtopicTitle: grantItem.subtopic_title || '',
    solicitationNumber: solicitation.solicitation_number || '',
    phase,
    closeDate,
    agency: agencyDetail,
  }))
})

export const SolicitationsSelector = {
  getSolicitation,
  getSolicitations,
  getSolicitationError,
  getSolicitationsError,
  fetchingSolicitation,
  fetchingSolicitations,
  getPaging,
  makeSolicitations,
  makeSolicitation,
  makeGrants,
}
