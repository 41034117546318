import { createSlice } from '@reduxjs/toolkit'
import { ISolicitation } from '../../types/Grants'
import { IPagingResponse } from '../../types/Requests'
import {
  fetchSolicitationAsync,
  fetchSolicitationAsyncBuilder,
  fetchSolicitationsAsync,
  fetchSolicitationsAsyncBuilder,
} from './asyncRequests'

export interface SolicitationsInitialState {
  solicitations: {
    data: ISolicitation[]
    fetching: boolean
    error: string
    paging: IPagingResponse
  }
  solicitation: {
    data: ISolicitation
    fetching: boolean
    error: string
  }
}

const initialState: SolicitationsInitialState = {
  solicitations: {
    data: [] as ISolicitation[],
    fetching: false,
    error: '',
    paging: {
      totalRows: 0,
      limit: 25,
      skip: 0,
      rows: 0,
      paging: true,
    },
  },
  solicitation: {
    data: {} as ISolicitation,
    fetching: false,
    error: '',
  },
}

const SolicitationsSlice = createSlice({
  name: 'solicitations',
  initialState,
  reducers: {
    resetSolicitation: (state) => {
      state.solicitation.data = initialState.solicitation.data
    },
    resetSolicitationError: (state) => {
      state.solicitation.error = initialState.solicitation.error
    },
    resetSolicitationsError: (state) => {
      state.solicitations.error = initialState.solicitations.error
    },
    setPaging: (state, action) => {
      const {
        limit = state.solicitations.paging.limit,
        skip = state.solicitations.paging.skip,
        paging = state.solicitations.paging.paging,
      } = action.payload

      state.solicitations.paging = { ...state.solicitations.paging, skip, limit, paging }
    },
  },
  extraReducers: (builder) => {
    fetchSolicitationAsyncBuilder(builder)
    fetchSolicitationsAsyncBuilder(builder)
  },
})

export const SolicitationsActions = {
  ...SolicitationsSlice.actions,
  fetchSolicitationAsync,
  fetchSolicitationsAsync,
}

export const SolicitationsReducer = SolicitationsSlice.reducer
