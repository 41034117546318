import { Close } from '@mui/icons-material'
import { Box, Drawer, SxProps, Tab, Tabs, Typography, useTheme } from '@mui/material'
import { FC, ReactNode, SyntheticEvent, useState } from 'react'

interface TechnologySlideOutProps {
  open: boolean
  onClose: Function
  children?: ReactNode
  children1?: ReactNode
}

const closeButtonStyles: SxProps = {
  mt: 3,
  width: 30,
  height: 28,
  background: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  cursor: 'pointer',
}

export const TechnologySlideOutLayout: FC<TechnologySlideOutProps> = ({
  open,
  onClose,
  children,
  children1,
}) => {
  const theme = useTheme()
  const [selectedTab, setSelectedTab] = useState<String>('matrices')

  const handleDrawerClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      onClose()
    }
  }

  const handleTabChange = (event: SyntheticEvent, newValue: String) => {
    setSelectedTab(newValue)
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleDrawerClose}
      elevation={0}
      sx={{
        '.MuiDrawer-paper': {
          background: 'transparent',
          overflow: 'hidden',
        },
      }}
    >
      <Box sx={{ width: { xs: '90vw', lg: '75vw' }, display: 'flex', height: '100%', gap: 2 }}>
        <Box sx={closeButtonStyles} onClick={() => onClose()}>
          <Close sx={{ fontSize: 18 }} />
        </Box>
        <Box
          sx={{
            background: theme.palette.grey[100],
            width: '100%',
            height: 'auto',
            overflowY: 'auto',
            overflowX: 'hidden',
            px: 2,
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            sx={{ borderBottom: `1px solid ${theme.palette.grey[300]}` }}
          >
            <Tab
              value="matrices"
              label={
                <Typography
                  fontSize="0.875rem"
                  fontWeight={theme.typography.fontWeightBold}
                  textTransform="capitalize"
                >
                  Technology Matrices
                </Typography>
              }
            />
            <Tab
              value="settings"
              label={
                <Typography
                  fontSize="0.875rem"
                  fontWeight={theme.typography.fontWeightBold}
                  textTransform="capitalize"
                >
                  Technology Settings
                </Typography>
              }
            />
          </Tabs>
          <Box sx={{ mt: 2 }}>
            {selectedTab === 'settings' && children}
            {selectedTab === 'matrices' && children1}
          </Box>
        </Box>
      </Box>
    </Drawer>
  )
}
