import { AddCircleRounded } from '@mui/icons-material'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { Button, Grid, IconButton, useMediaQuery, useTheme } from '@mui/material'
import { MouseEvent, useState } from 'react'
import { useSelector } from 'react-redux'
import { RightSlider } from '../../../../components/sliders'
import { SolicitationsSelector } from '../../../../store/solicitations'
import { AddToTechnologyModal } from './AddToTechnologyModal'
import { Grant } from './Grant/Grant'
import { GrantCard } from './GrantCard'

const addTechBtnStyles = {
  fontWeight: 700,
  fontSize: '0.8125rem',
  borderRadius: '0.5rem',
  textTransform: 'capitalize',
}

export const Grants = () => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down('sm'))

  const [selectedGrantId, setSelectedGrantId] = useState('')
  const [showModel, setShowModel] = useState(false)
  const [openSlider, setOpenSlider] = useState(false)

  const grants = useSelector(SolicitationsSelector.makeGrants)

  const handleAddToTechnology = async (e: MouseEvent<HTMLButtonElement>, grantId: string) => {
    e.stopPropagation()
    setSelectedGrantId(grantId)
    setShowModel(true)
  }

  const handleSlider = (grantId: string) => {
    setSelectedGrantId(grantId)
    setOpenSlider(true)
  }

  return (
    <>
      <AddToTechnologyModal open={showModel} setOpen={setShowModel} grantId={selectedGrantId} />

      <RightSlider
        open={openSlider}
        setOpen={setOpenSlider}
        closeButtonAction={() => setOpenSlider(false)}
      >
        <Grant grantId={selectedGrantId} />
      </RightSlider>

      <Grid container spacing={3} my={3}>
        {grants &&
          grants.map((item) => {
            const { topicTitle, solicitationNumber, agency, closeDate, _id, phase } = item

            const AddToTechnologyButton = xs ? (
              <IconButton onClick={(e) => handleAddToTechnology(e, _id)}>
                <AddCircleRounded color="primary" />
              </IconButton>
            ) : (
              <Button
                sx={addTechBtnStyles}
                startIcon={<AddOutlinedIcon />}
                onClick={(e) => handleAddToTechnology(e, _id)}
              >
                Add To Technology
              </Button>
            )

            return (
              <Grid item key={_id} xs={12} md={6} onClick={() => handleSlider(_id)}>
                <GrantCard
                  grantTitle={topicTitle}
                  phase={phase}
                  agency={agency}
                  deadline={closeDate}
                  addToTechBtn={AddToTechnologyButton}
                  solicitationNumber={solicitationNumber}
                />
              </Grid>
            )
          })}
      </Grid>
    </>
  )
}
