import { AxiosAPI } from './AxiosApi'

class GrantsApi extends AxiosAPI {
  public fetchSearchedGrants = async (body: any, options = {}) => {
    return this.POST(this.apiSchema.POST.grants.search(), body, options)
  }

  public createShareableGrants = async (grantId: string, options = {}) => {
    return this.POST(this.apiSchema.POST.grants.sharedGrants(grantId), options)
  }

  public fetchGrants = async (options = {}) => {
    return this.GET(this.apiSchema.GET.grants.grants(), options)
  }

  public fetchGrant = async (id: string, options = {}) => {
    return this.GET(this.apiSchema.GET.grants.grant(id), options)
  }
}

export default new GrantsApi()
