import { Button, useTheme } from '@mui/material'
import { CsvBuilder } from 'filefy'
import moment from 'moment'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ExportIcon } from '../../../../components/icons'
import { SnackbarActions } from '../../../../store/snackbar'
import { TechGrantSelectors } from '../../../../store/technology'
import { MatchedGrantStatus } from '../../../../types/Grants'

export const ExportGrantsButton: FC<{ selectedTab: MatchedGrantStatus }> = ({ selectedTab }) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const grantsToExport = useSelector((state) =>
    TechGrantSelectors.makeExportGrants(state, selectedTab),
  )

  const exportGrantsToCsv = () => {
    try {
      if (!grantsToExport) return

      const { rows, columns } = grantsToExport
      const builder = new CsvBuilder(
        `GrantMatch_${selectedTab}_${moment().format('YYYY-MM-DD')}.csv`,
      )

      builder.setColumns(columns).addRows(rows).exportFile()

      dispatch(
        SnackbarActions.showSnackbar({
          message: 'CSV file exported!!!',
          options: { variant: 'success' },
        }),
      )
    } catch (error) {}
  }

  return (
    <Button
      variant="contained"
      disableElevation
      disabled={!grantsToExport}
      startIcon={<ExportIcon />}
      sx={{
        position: 'absolute',
        transform: 'translateY(calc(-100% - 0px))',
        right: 0,
        zIndex: 1,
        width: '170px',
        borderRadius: 2,
        textTransform: 'none',
        padding: '0.5rem 1rem',
        marginBottom: 0,
        [theme.breakpoints.down('md')]: {
          top: '100%',
          transform: 'translateY(calc(100% - 25px))',
        },
      }}
      onClick={exportGrantsToCsv}
    >
      Export as CSV
    </Button>
  )
}
