import { Box, Paper, Typography, useTheme } from '@mui/material'
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { useSelector } from 'react-redux'
import { MatricesSelector } from '../../../../store/TechnologyMatrices'

ChartJS.register(ArcElement, Tooltip, Legend)

export const DoughnutChart = () => {
  const theme = useTheme()
  const winPercentage = useSelector(MatricesSelector.selectWinPercentage)
  const lossPercentage = useSelector(MatricesSelector.selectLossPercentage)

  let data = [
    {
      label: 'Wins',
      value: winPercentage,
      color: '#00A94F',
      cutout: '50%',
    },
    {
      label: 'Losses',
      value: lossPercentage,
      color: '#F46E6F',
      cutout: '50%',
    },
  ]

  const options: any = {
    plugins: {
      responsive: true,
      legend: {
        display: true,
        position: 'right',
        labels: {
          usePointStyle: true,
          padding: 20,
          generateLabels: (chart: any) => {
            const data = chart.data
            if (data.labels.length && data.datasets.length) {
              return data.labels.map((label: any, index: any) => {
                const dataset = data.datasets[0]
                const backgroundColor = dataset.backgroundColor[index]
                return {
                  text: `${label} - ${dataset.data[index]}%`,
                  fillStyle: backgroundColor,
                  strokeStyle: backgroundColor,
                  lineWidth: 1,
                  hidden: isNaN(dataset.data[index]) || dataset.data[index] === 0,
                  index: index,
                }
              })
            }
            return []
          },
        },
        tooltip: {
          callbacks: {
            label: (tooltipItem: any) => {
              return `${tooltipItem.label}: ${tooltipItem.raw}%`
            },
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem: any) => {
            const label = tooltipItem.label
            const value = tooltipItem.raw
            return `${label}: ${value}%`
          },
        },
      },
      title: {
        display: false,
      },
    },
    cutout: data.map((item) => item.cutout),
    responsive: true,
    maintainAspectRatio: false,
  }

  const finalData = {
    labels: data.map((item) => item.label),
    datasets: [
      {
        data: data.map((item) => Math.round(item.value)),
        backgroundColor: data.map((item) => item.color),
        borderColor: data.map((item) => item.color),
        borderWidth: 1,
        dataVisibility: new Array(data.length).fill(true),
      },
    ],
  }

  return (
    <Paper
      elevation={0}
      style={{ padding: '20px', width: '100%', border: `1px solid ${theme.palette.grey[200]}` }}
    >
      <Box
        display="flex"
        justifyContent="flex-start"
        flexDirection="column"
        alignItems="flex-start"
        width="100%"
        height="100%"
        p={4}
      >
        <Box width="100%">
          <Typography variant="h5" fontWeight={theme.typography.fontWeightBold}>
            Wins and Losses chart
          </Typography>
        </Box>
        <Box width={500} height={500}>
          <Doughnut data={finalData} options={options} />
        </Box>
      </Box>
    </Paper>
  )
}
